import React from 'react';
import { useStore } from '../../../store/useStore';

export default function UserSkill({ id }) {
	const { state } = useStore();

	const skill = state?.skills?.items.find((item) => {
		return item.id === id;
	});

	if (!skill) {
		return <></>;
	}

	return <span className={`tag tag-${skill.id}`}>{skill.name}</span>;
	// return <Link to={`/skills/${skill.id}`} className={`tag tag-${skill.id}`}>{skill.name}</Link>;
}


export const UserSkills = ({ skills }) => {	

	// eslint-disable-next-line eqeqeq
	if (!skills || skills?.length == 0) {
		return <></>;
	}


	return (
		<div className="skills">
			{skills.map((i) => {
				return <UserSkill key={i.id} id={i.id} />; 
			})}
		</div>
	);
};
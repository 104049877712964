import React, { useEffect, useRef, useState } from "react";

const Drawer = ({ isOpen, onClose, children, className, title }) => {
	const [isVisible, setIsVisible] = useState(false); // Controls the animation

	const drawerRef = useRef(null);
	const firstFocusableElement = useRef(null);
	const lastFocusableElement = useRef(null);

	useEffect(() => {
		if (isOpen) {
			setIsVisible(true); // Render the component

			// Delay adding the 'open' class to trigger animation
			setTimeout(() => {
				if (!!drawerRef.current) {
					drawerRef.current.classList.add("open");
				}
			}, 10); // Slight delay for CSS animation
		} else {
			if (drawerRef.current) {
				drawerRef.current.classList.remove("open"); // Remove 'open' class to start closing animation
			}

			const timer = setTimeout(() => setIsVisible(false), 300); // Delay hiding the drawer until after the animation
			return () => clearTimeout(timer);
		}
	}, [isOpen]);

	// Focus trapping logic remains the same
	useEffect(() => {
		if (!isOpen || !drawerRef.current) return;

		const focusableElements = drawerRef.current.querySelectorAll(
			'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
		);

		if (focusableElements.length > 0) {
			firstFocusableElement.current = focusableElements[0];
			lastFocusableElement.current =
				focusableElements[focusableElements.length - 1];

			// Focus the first focusable element
			firstFocusableElement.current.focus();
		}

		const trapFocus = (e) => {
			if (e.key === "Tab") {
				if (e.shiftKey) {
					if (document.activeElement === firstFocusableElement.current) {
						e.preventDefault();
						lastFocusableElement.current.focus();
					}
				} else {
					if (document.activeElement === lastFocusableElement.current) {
						e.preventDefault();
						firstFocusableElement.current.focus();
					}
				}
			} else if (e.key === "Escape") {
				onClose();
			}
		};

		document.addEventListener("keydown", trapFocus);
		return () => document.removeEventListener("keydown", trapFocus);
	}, [isOpen, onClose]);

	const handleOverlayClick = () => onClose();
	const handleDrawerClick = (e) => e.stopPropagation();

	if (!isVisible) return null; // Drawer is not visible yet

	return (
		<div
			className={`drawer-overlay ${isOpen ? "open" : ""} ${className}`}
			onClick={handleOverlayClick}
			role="dialog"
			aria-modal="true"
		>
			<div
				className="drawer"
				onClick={handleDrawerClick}
				ref={drawerRef}
				aria-labelledby="drawer-title"
				aria-describedby="drawer-description"
			>
				<button
					className="close-btn"
					onClick={onClose}
					aria-label="Close drawer"
				>
					&times;
				</button>
				<h2 id="drawer-title">{title}</h2>
				<div id="drawer-description">{children}</div>
			</div>
		</div>
	);
};

export default Drawer;
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useStore } from "../../store/useStore";
import { NavLink } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import Timestamp from "../Components/common/Timestamp";
import RequestPermissionToggle from "../Components/common/RequestPermissionToggle";
import { Contact } from "./Contacts";
import MainTitle from "../Components/common/MainTitle";
import { apiClient } from "../Helpers/apiCall";
import { HashLink } from "react-router-hash-link";
import { NPSTrigger } from "../Components/NPS";
import Loader from "../Components/common/Loader";

import {
	CustomPictureSelector, createHandlers
} from "../Components/common/CustomInput";
import Drawer from "../Components/common/Drawer";
import ReactSelect from 'react-select';

export default function Profile({ reEnableOnboarding }) {
	const { state, dispatch } = useStore();
	const { user, users } = state;

	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	const openDrawer = () => setIsDrawerOpen(true);
	const closeDrawer = () => setIsDrawerOpen(false);

	const [showNotificationDebug, setShowNotificationDebug] = useState(false);

	// const [isEditor, setIsEditor] = useState(false);
	const properUser = users.items.find(
		(i) => parseInt(i.id) === parseInt(user.data.id)
	);

	const logoutAction = () => {
		dispatch({ type: "LOGOUT" });
	};

	// const saveProfile = (data) => {
	// 	if (data) {
	// 		apiCall.EDIT("users", user.data.id, data).then((response) => {
	// 			if (response.status === 200) {
	// 				dispatch({ type: "USER_REFRESH", payload: response });
	// 			}
	// 		});
	// 	}
	// };

	// const useProfileButton = (user?.data?.group || []).find(i => i.id == 6) !== undefined;
	// console.log('useProfileButton', useProfileButton, user?.data?.group ?? '');

	// const allowedUsers = [1, 330, 346, 392];
	// const useProfileButton = allowedUsers.includes(user?.data?.id);
	const useProfileButton = true;

	const checkForUpdates = () => {
		if (window && window.location) {
			window.location.reload(true);
		}
	};

	const EditProfileButton = useProfileButton ? (
		<button onClick={openDrawer} className="c-button">
			Edit Profile
		</button>
	) : (null);

	return (
		<div>
			<MainTitle>Your Profile</MainTitle>

			<div className="c-page">
				<div className="c-profile">
					<div className="c-person profile ">
						<Contact user={properUser} append={EditProfileButton} />

						<Drawer
							isOpen={isDrawerOpen}
							onClose={closeDrawer}
							title={"Edit Profile"}
							className="profile-drawer"
						>
							<EditProfile user={user?.data} closeDrawer={closeDrawer} />
						</Drawer>
					</div>

					<div className="tools">
						<RequestPermissionToggle loaderClass="light" useIcon={true} />

						<div className="notifications-debugger">
							<button
								onClick={() => setShowNotificationDebug(!showNotificationDebug)}
							>
								<span className="icon"></span>
								Trouble with notifications?
							</button>
							{showNotificationDebug && (
								<div className="notification-debug">
									<p>
										Make sure you have updated your iOS/Android and browser to
										the latest version. Notifications also work better with
										saved apps.
									</p>
									<p>
										<strong>Notifications supported?</strong>
										<br />
										<span>
											{typeof Notification !== "undefined" &&
											"Notification" in window
												? "Yes"
												: "No"}
										</span>
									</p>
									<p>
										<strong>Notifications in use?</strong>
										<br />
										<span>
											{typeof Notification !== "undefined" &&
												(Notification?.permission ?? "-")}
										</span>
									</p>

									{/* <p>
										<label>
											<strong>Saved notification data:</strong>
										</label>
										<span
											style={{
												margin: 0,
												wordBreak: 'break-word',
												fontSize: '0.8em',
											}}
										>
											{user?.data?.notification_settings ?? '-'}
										</span>
									</p> */}
								</div>
							)}
						</div>

						<div className="applauds">
							<HashLink
								className="applauds"
								to={`/draamattupiiri/#${user?.data?.email}`}
								rel="noopener"
							>
								<span className="icon"></span>
								Check your compliments &raquo;
							</HashLink>
						</div>
						<div className="nps">
							<NPSTrigger>
								<button>
									<span className="icon"></span>
									Tell us how you're feeling &raquo;
								</button>
							</NPSTrigger>
						</div>
						<div className="feedback">
							<NavLink activeClassName="active" to={"/feedback"}>
								<span className="icon"></span>
								Leave feedback &raquo;
							</NavLink>
						</div>
						<div className="onboarding">
							<button onClick={reEnableOnboarding}>
								<span className="icon"></span>
								See tips & instructions &raquo;
							</button>
						</div>
						<div className="notifications-debugger">
							<button onClick={checkForUpdates}>
								<span className="icon"></span>
								Reload &amp; Check for updates
							</button>
						</div>
					</div>
					<div className="last-login">
						<p>
							Last login: <Timestamp timestamp={user?.data?.last_login} />
						</p>
					</div>
					<div className="buttons">
						<button className="signout-button" onClick={logoutAction}>
							<span className="icon">
								<svg
									width="19"
									height="18"
									viewBox="0 0 19 18"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M8.153 1.613l.892-.867a.98.98 0 011.362 0l7.81 7.59a.916.916 0 010 1.324l-7.81 7.594a.98.98 0 01-1.362 0l-.892-.867a.92.92 0 01.016-1.34l4.841-4.485H1.464A.949.949 0 01.5 9.626v-1.25c0-.52.43-.938.964-.938H13.01L8.17 2.954a.914.914 0 01-.017-1.34z"
										fill="#FFF"
										fillRule="nonzero"
									/>
								</svg>
							</span>
							Log out
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export const EditProfile = ({ user, closeDrawer }) => {
	const { state, dispatch } = useStore();
	const [data, setData] = useState({});
	const fileSelectorRef = useRef();
	const isUploading = state.common.isUploading;

	const formRef = useRef();
	const { id } = user;

	const ENDPOINT_PLURAL = "profiles";	

	useEffect(() => {
		let isMounted = true;
		if (id) {
			apiCall.GET_USER(id).then((response) => {
				if (response.status === 200) {
					if (isMounted) {
						setData({
							...response.data.data,
						});
					}
				}
			});
		}
		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	if (!user) {
		return <Loader className=""></Loader>;
	}

	const apiCall = apiClient(state, dispatch);
	const {
		handleOnChange,
		sendFile,
	} = createHandlers(data, setData, apiCall, state);

	const saveProfile = (id, saveData, onComplete) => {
		const { thumb_id, skill } = saveData;
		const realSaveData = { thumb_id, skill };

		if (realSaveData) {
			return apiCall.EDIT(ENDPOINT_PLURAL, id, realSaveData).then((response) => {
				if (response.status === 200) {
					dispatch({ type: "USER_REFRESH", payload: response });
					apiCall.GET_USER(id);
					if (typeof onComplete === "function") {
						onComplete();
					}
				}
			});
		}
		return false;
	};

	const onChangeImage = (file, ikey) => {
		var sf = sendFile(file, ikey);
		Promise.resolve(sf).then((response) => {
			if (response) {
				saveProfile(id, response);
			} else {
				console.error("sf failed");
			}
		});
	};

	const customErrors = [];

	const onSubmit = (e) => {
		const form = formRef.current;
		const isValid = form && form.checkValidity();
		const isCustomValid = customErrors.length === 0;

		if (id) {
			if (isValid) {
				e.preventDefault();
				if (isCustomValid) {
					var saveData = { ...data };
					saveProfile(data.id, saveData, closeDrawer);
				} else {
					window.scrollTo({ top: 0, behavior: "smooth" });
				}
			}
		}
	};

	if ((id && !data.id) || state.users.loading || data.loading) {
		return <Loader className="dark"></Loader>;
	}

	return (
		<section id={user.slug}>
			{/* <BackButton /> */}
			<div className="">
				<h1>
					<span>{user.last_name}</span>, {user.first_name}
				</h1>
				<div className="ximage">
					<CustomPictureSelector
						data={data}
						field={"thumb_id"}
						handleOnChange={onChangeImage}
						fileSelectorRef={fileSelectorRef}
						autocomplete={"photo"}
						isRequired={false}
						isUploading={isUploading}
					/>
				</div>
				<div className="xtext">
					<form onSubmit={onSubmit} ref={formRef}>
						<fieldset id="perustiedot">
							<legend>Basic information:</legend>
							<br />
							<div className="input-wrapper">
								<label>
									{data.first_name} {data.last_name}
								</label>
								<label>{data.title}</label>
								<label>{data.phone}</label>
								{/* <div className="input-wrapper">
									<CustomInput
										label={"First name"}
										field={"first_name"}
										handleOnChange={handleOnChange}
										data={data}
									/>
								</div>
								<div className="input-wrapper">
									<CustomInput
										label={"Last name"}
										field={"last_name"}
										handleOnChange={handleOnChange}
										data={data}
									/>
								</div>
								<div className="input-wrapper">
									<CustomInput
										label={"Title"}
										field={"title"}
										handleOnChange={handleOnChange}
										data={data}
									/>
								</div>
								<div className="input-wrapper">
									<label>
										<span className="label">Phone</span>
										<PhoneInput
											international={true}
											// country='FI'
											defaultCountry={"FI"}
											value={data.phone}
											withCountryCallingCode={true}
											onChange={(val) => {
												handleOnChange({
													target: { name: "phone", value: val },
												});
											}}
										/>
										<span className="dim">({data.phone})</span>
									</label>
								</div>
							</div>	<div className="input-wrapper">
	</span>								<span className="label">
										<CustomCheckbox
											subLabel={"Hide phone number"}
											field={"is_private"}
											handleOnChange={handleSwitch}
											data={data}
										/>
									</span>
								</div> */}
							</div>
						</fieldset>

						<fieldset>
							<legend>Podlings:</legend>
							<br />
							<div className="input-wrapper">
								{data.pod.map((pod) => {
									const nameOfPod =
										state?.pods?.items.find((p) => p.id === pod.id).name ??
										"---";

									return <div key={pod.id}>&bull; {nameOfPod}</div>;
								})}
							</div>

							{/* <div className="in</div>put-wrapper">
								<CustomCheckboxes
									label={"Podlings"}
									subLabel={"---"}
									field={"pod"}
									handleOnChange={handleOnChangeForCheckBoxes}
									data={data}
									options={podsOptions}
								/>
							</div> */}
						</fieldset>

						<fieldset>
							<legend>Highlighted skills:</legend>
							<br />
							{/* <div className="input-wrapper">[Way to select skills here]</div> */}
							<div
								className="input-wrapper skills-selection"
								style={{ color: "black" }}
							>
								{/* <pre>{JSON.stringify(data, null, 2)}</pre>
								<pre>{JSON.stringify(skillsOptions, null, 2)}</pre> */}
								{/* <CustomCheckboxes
									label={"Skills"}
									subLabel={"---"}
									field={"skill"}
									handleOnChange={handleOnChangeForCheckBoxes}
									data={data}
									options={skillsOptions}
								/> */}
								<CustomSkillSelector
									options={state?.skills?.items ?? []}
									onChange={handleOnChange}
									value={data.skill ?? []}
								/>
							</div>
						</fieldset>

						<div className="submit-holder">
							{false && <Loader></Loader>}
							<button type="submit" className="c-button" onClick={onSubmit}>
								{data.loading && <Loader></Loader>}
								{data.loading ? "" : "Save changes"}
							</button>
						</div>
					</form>
				</div>
			</div>
		</section>
	);
};

export const CustomSkillSelector = ({ options, onChange, value }) => {

	const [selectedOptions, setSelectedOptions] = useState(value);

	useEffect(() => {
		setSelectedOptions(value);
	}, [value]);

	const groupedOptions = useMemo(() => {
		return Object.entries(
			options.reduce((acc, skill) => {
				const category = options.find((cat) => cat.id === skill.p_id)?.name || false;
				if (!category) return acc;
				if (!acc[category]) {
					acc[category] = [];
				}
				acc[category].push({ value: skill.id, label: skill.name });
				return acc;
			}, {})
		).map(([label, options]) => ({
			label,
			options: options.sort((a, b) => a.label.localeCompare(b.label)),
		}));
	}, [options]);


	const handleChange = (selectedOption) => {
		// const newOptions = selectedOptions.some(option => option.value === selectedOption.value)
		// 	? selectedOptions.filter(option => option.value !== selectedOption.value)
		// 	: [...selectedOptions, selectedOption];
		const newOptions = value.some(option => option.id === selectedOption.value)
			? value.filter(option => option.id !== selectedOption.value)
			: [...selectedOptions, selectedOption];

		// setSelectedOptions(newOptions);
		onChange({
			target: { name: 'skill', value: newOptions.map(i => ({ id: i.id || i.value })) }
		});
	};

	return (
		<>
			<div className='skill-list'>
				{value.map(option => (
					<button className='tag' key={option.id} onClick={() => handleChange({ value: option.id })}>						
						{ options.find(i => i.id == option.id)?.name ?? '???' }
					</button>
				))}
			</div>
			<ReactSelect
				isMulti={false}
				name="skills"
				value={null}
				// value={selectedOption}
				onChange={handleChange}
				placeholder="Select your skills"
				options={groupedOptions}
			/>
		</>
	);
}

/* eslint-disable eqeqeq */
import React, { useState, useEffect, useRef } from 'react';
import { Redirect, Route, Switch, Link } from 'react-router-dom';
import { useStore } from '../../../store/useStore';
import { apiClient } from '../../Helpers/apiCall';
import { NavTab } from 'react-router-tabs';

import UsersList from '../../Components/admin/UsersList';

import {
	CustomInput, CustomSelect, CustomSwitch,
	createHandlers
} from '../../Components/common/CustomInput';

import Loader from '../../Components/common/Loader';

export default function Skill(props) {
	const { state, dispatch } = useStore();
	const apiCall = apiClient(state, dispatch);
	const [data, setData] = useState({});
	const [redirect, setRedirect] = useState(false);
	const formRef = useRef();
	const { id, match } = props;

	const ENDPOINT_PLURAL = 'skills';

	useEffect(() => {
		let isMounted = true;
		if (id) {
			apiCall.GET_SKILL(id).then((response) => {
				if (response.status === 200) {
					if (isMounted) {
						setData({
							...response.data.data,
						});
					}
				}
			});
		}
		return () => {
			isMounted = false;
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const {
		handleOnChange,
		handleSwitch,
	} = createHandlers(data, setData, apiCall, state);

	const customErrors = [];

	const onSubmit = (e) => {
		const form = formRef.current;
		const isValid = form && form.checkValidity();
		const isCustomValid = customErrors.length === 0;

		if (id) {
			if (isValid) {
				e.preventDefault();
				if (isCustomValid) {
					var saveData = { ...data };
					apiCall.EDIT(ENDPOINT_PLURAL, id, saveData).then((response) => {
						if (response.status === 200) {
							apiCall.GET_SKILL(data.id);
						}
					});
				} else {
					window.scrollTo({ top: 0, behavior: 'smooth' });
				}
			}
		}
	};

	const remove = () => {
		if (window.confirm('Haluatko varmasti poistaa tämän?')) {
			apiCall.DELETE(ENDPOINT_PLURAL, data.id).then(() => {
				apiCall.GET(ENDPOINT_PLURAL);
				setRedirect(true);
			});
		}
	};

	if (redirect === true) {
		return <Redirect to={`/admin/${ENDPOINT_PLURAL}`} />;
	}

	if ((id && !data.id) || state.skills.loading || data.loading) {
		return <Loader className="dark"></Loader>;
	}

	const parentSkillOptions = state.skills?.items?.filter(i => !i.p_id).map((skill) => {
		return {
			id: skill.id,
			name: skill.name,
		};
	}) ?? [];
	parentSkillOptions.unshift({ value: null, name: "---" });
	
	return (
		<div className="company-form">
			<header>
				<div className="inner">
					<div className="top">
						<h1>
							<span className="category">
								<Link to={"/admin/skills"} className="">
									Skill /
								</Link>{" "}
								#{data.id}
							</span>
							{data.name === "" || data.name === null
								? "Nimeämätön kohde "
								: `${data.name}`}
						</h1>
					</div>
				</div>
			</header>
			<div className="header-bottom">
				<nav>
					<ul className="max-width">
						<li>
							<NavTab to={`${match.url}/info`}>Yleisinfo</NavTab>
						</li>
					</ul>
				</nav>
			</div>

			<Switch>
				<Route
					exact
					path={`${match.path}`}
					render={() => <Redirect replace to={`${match.url}/info`} />}
				/>

				<Route exact path={`${match.url}/info`}>
					<section>
						<form onSubmit={onSubmit} ref={formRef}>
							<fieldset id="tila">
								<legend>Tila</legend>
								<div className="input-wrapper">
									<CustomSwitch
										label={"Julkaistu?"}
										field={"is_published"}
										handleOnChange={handleSwitch}
										data={data}
									/>
								</div>
							</fieldset>
							<fieldset id="perustiedot">
								<legend>Perustiedot:</legend>
								<div className={`grid-flex`}>
									<div>
										<div className="input-wrapper">
											<CustomInput
												label={"Nimi"}
												field={"name"}
												handleOnChange={handleOnChange}
												data={data}
											/>
										</div>
									</div>
								</div>
								<div className={`grid-flex`}>
									<div>
										<div className="input-wrapper">
											<CustomSelect
												label={"Pääkategoria"}
												field={"p_id"}
												handleOnChange={handleOnChange}
												data={data}
												options={parentSkillOptions}
											/>
										</div>
									</div>
								</div>
							</fieldset>
						</form>

						<fieldset>
							<legend>Käyttäjät:</legend>
							{/* <UsersList preFilter={(u) => u.skill_id == data.id} hideHeader /> */}
							<UsersList
								preFilter={(u) => (u.skill ?? []).some((r) => data.id == r.id)}
								hideHeader
							/>
						</fieldset>
						<fieldset>
							<legend>Toiminnot</legend>
							{data.id && (
								<button
									type="button"
									className="button delete"
									onClick={remove}
								>
									Poista?
								</button>
							)}
						</fieldset>
					</section>
				</Route>
			</Switch>

			<div className="submit-holder">
				<button
					type="submit"
					className="button"
					form="place-form"
					onClick={onSubmit}
				>
					{data.loading && <Loader></Loader>}
					{data.loading ? "" : data.id ? "Tallenna muutokset" : "Lisää kohde"}
				</button>
			</div>
		</div>
	);
}

import { toast } from 'react-toastify';
import { replaceOrAddById } from '../App/Helpers/store';

export const skillsInitialState = {
	loading: false,
	items: [],
};

export const skillsReducer = (state = skillsInitialState, action) => {
	switch (action.type) {
		case 'SKILLS_ADD':
		case 'SKILLS_DELETE':
			return state;

		case 'SKILLS_EDITING':
		case 'SKILLS_LOADING':
			return {
				...state,
				loading: true,
			};

		case 'SKILLS_GET':
			return {
				...state,
				loading: false,
				items: action.payload.data.data,
			};

		case 'SKILLS_ERROR':
			toast.error('Virhe tietojen tallennuksessa');
			return {
				...state,
				loading: false,
			};
		case 'SKILLS_EDITED':
			toast.success('Tiedot tallennettu');
			return {
				...state,
				loading: false,
			};

		case 'SKILL_SET_LOADING':
			let nitems = [...state.items];
			let nindex = nitems.findIndex((val) => {
				// eslint-disable-next-line eqeqeq
				return val.id == action.id;
			});
			if (nindex > -1) {
				nitems[nindex].loading = true;
			}

			return {
				...state,
				items: nitems,
				loading: false,
			};

		case 'SKILL_GET':
			const items = replaceOrAddById(
				[...state.items],
				action.payload.data.data
			);

			return {
				...state,
				items,
				loading: false,
			};

		default:
			return state;
	}
};

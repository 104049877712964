import React from 'react';
import { useStore } from '../../../store/useStore';
import { Link } from 'react-router-dom';

export default function UserPodling({ id }) {
	const { state } = useStore();

	const podling = state?.pods?.items.find((item) => {
		return item.id === id;
	});

	if (!podling) {
		return <></>;
	}

	return <Link to={`/podlings/${podling.id}`} className={`tag tag-${podling.id}`}>{podling.name}</Link>;
}


export const UserPodlings = ({ pods }) => {	

	// eslint-disable-next-line eqeqeq
	if (!pods || pods?.length == 0) {
		return <></>;
	}


	return (
		<div className="pods">
			{pods.map((i) => {
				return <UserPodling key={i.id} id={i.id} />; 
			})}
		</div>
	);
};
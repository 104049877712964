/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
// import { apiClient } from '../Helpers/apiCall';
import { useStore } from "../../store/useStore";
import Loader from "../Components/common/Loader";
// import BackButton from '../Components/common/BackButton';

import { config } from "../config";
import { Link } from "react-router-dom";
// import { UserGroups } from '../Components/common/UserGroup';
import placeholderImg from "../../images/icon_roster.svg";
import MainTitle from "../Components/common/MainTitle";
import { UserPodlings } from "../Components/common/UserPodling";
import { UserSkills } from '../Components/common/UserSkill';

export default function Contacts({ contactId }) {
	const [activeNavItem, setActiveNavItem] = useState(0);
	const { state } = useStore();
	// const apiCall = apiClient(state, dispatch);
	const { users } = state;

	const user = users.items.find((i) => parseInt(i.id) === parseInt(contactId));
	// const baseUrl = '/contacts';

	const lastNames = new Set(
		(users?.items ?? [])
			.reduce((result, u) => {
				if (u.last_name) {
					result.push(u.last_name.toUpperCase().substring(0, 1));
				}
				return result;
			}, [])
			.sort()
	);

	const sortedUsers = (users?.items ?? []).sort((a, b) => {
		return a.last_name.localeCompare(b.last_name, config.localeCompare.locale);
	});

	var prevUser = false;

	return (
		<div>
			<MainTitle>Roster</MainTitle>
			{contactId && <Contact user={user} showButtons={true} />}
			{!contactId && (
				<section>
					{/* <BackButton /> */}
					<div className="c-contacts">
						<div className="nav">
							<div className="inner">
								{[...lastNames].map((l, index) => {
									return (
										<a
											key={`nav-${l}`}
											href={`#skip-to-${l}`}
											className={index === activeNavItem ? "active" : ""}
											onClick={() => setActiveNavItem(index)}
										>
											{l}
										</a>
									);
								})}
							</div>
						</div>
						{users.loading && <Loader />}
						{sortedUsers && (
							<ul>
								{sortedUsers.map((user) => {
									const userLetter = (user?.last_name ?? "")
										.substring(0, 1)
										.toUpperCase();

									const letterAnchor =
										userLetter !==
										(prevUser?.last_name ?? "").substring(0, 1).toUpperCase();

									prevUser = user;

									return (
										<li key={user.id}>
											<ContactLink
												user={user}
												letterAnchor={letterAnchor}
												userLetter={userLetter}
											/>
										</li>
									);
								})}
							</ul>
						)}
						{/* <div className="end-of-list">...</div> */}
					</div>
				</section>
			)}
		</div>
	);
}

export const TinyContactLink = ({ user }) => {
	const baseUrl = "/contacts";
	const publishClasses = user?.is_published ? "is_published" : "not_published";

	return (
		<Link className={` ${publishClasses}`} to={`${baseUrl}/${user.id}`}>
			<span className="last-name">{user.last_name}</span>{" "}
			<span className="first-name">{user.first_name}</span>
		</Link>
	);
};

export const ContactLink = ({
	user,
	letterAnchor = false,
	userLetter = false,
}) => {
	const baseUrl = "/contacts";
	const hasImg = user?.thumb_id ?? false;
	// const firstGroupId = user?.group[0]?.id ?? '';

	const publishClasses = user?.is_published ? "is_published" : "not_published";
	return (
		/* TODO: User can belong to multiple groups, how to style? */
		<Link
			className={`c-contacts-item ${publishClasses}`}
			to={`${baseUrl}/${user.id}`}
		>
			<div className="user-details">
				<p className="name">
					<span className="last-name">{user?.last_name ?? ""}</span>{" "}
					<span className="first-name">{user?.first_name ?? ""}</span>
				</p>
				<p className="title">{user.title}</p>
				<p className="phone">{user.phone}</p>
				<p className="email">
					<span>{user?.email?.split("@")[0] ?? ""}</span>
					<wbr />
					<span>@</span>
					<span>{user?.email?.split("@")[1] ?? ""}</span>
				</p>
			</div>
			<div className="user-img">
				{!hasImg && (
					<img
						src={placeholderImg}
						alt="profile"
						loading="lazy"
						className="thumb mini"
					/>
				)}
				{hasImg && (
					<img
						src={config.apiUrl + "/file/" + hasImg + "/preset=thumb"}
						alt="profile"
						loading="lazy"
						className="thumb mini"
					/>
				)}
			</div>
			{/* <div className="groups ">
				{user.group.map((i) => (
					<span
						className={`group user-group-${i.id}`}
						key={`group-${i.id}`}
					></span>
				))}
			</div> */}
			{letterAnchor && (
				<span
					id={`skip-to-${userLetter}`}
					className="letterAnchor"
					style={{ fontSize: "1px" }}
				>
					{userLetter}
				</span>
			)}
		</Link>
	);
};

export const Contact = ({
	user,
	showButtons = false,
	onEdit,
	append = undefined,
}) => {
	if (!user) {
		return <Loader className=""></Loader>;
	}

	const hasImg = user?.thumb_id ?? false;
	const imgSrc = hasImg
		? config.apiUrl + "/file/" + hasImg + "/preset=person"
		: placeholderImg;

	return (
		<section id={user.slug} className={`c-page contact-user `}>
			{/* <BackButton /> */}
			<div className="c-person">
				<div className="row">
					<div className="image">
						<img
							src={imgSrc}
							alt="profile"
							loading="lazy"
							className="thumb mini"
						/>
					</div>
					<div className="text">
						<h1>
							<span>{user.last_name}</span>, {user.first_name}
						</h1>
						{user.title && (
							<p className="italic">
								<strong>{user.title}</strong>
								{/* TODO: User can belong to multiple groups, how to style? */}
								{/* <UserGroup groupId={user.group_id} /> */}
								{/* <UserGroups groups={user.group} /> */}
							</p>
						)}
						{(user.email || user.phone) && (
							<p>
								{!user.is_private && user.phone && (
									<a href={`tel:${user.phone}`} target="_blank" rel="noopener">
										{user.phone}
										<br />
									</a>
								)}
								<a href={`mailto:${user.email}`} target="_blank" rel="noopener">
									{user.email}
								</a>
							</p>
						)}

						<div className="user-skills">
							<UserSkills skills={user.skill} />
						</div>
						<div className="user-podlings">
							<UserPodlings pods={user.pod} />
						</div>

						{showButtons && (
							<div className="buttons">
								<Link
									className="applauds"
									to={`/draamattupiiri`}
									rel="noopener"
								>
									<span className="icon"></span>
									Leave applauds
								</Link>
								{!user.is_private && user.phone && (
									<a
										className="phone"
										target="_blank"
										href={`tel:${user.phone}`}
										rel="noopener"
									>
										<span className="icon"></span>
										Call
									</a>
								)}
								{user.email && (
									<a
										className="email"
										target="_blank"
										href={`mailto:${user.email}`}
										rel="noopener"
									>
										<span className="icon"></span>
										Send email
									</a>
								)}
								{/* <Link to="">Linkedin</Link> */}
							</div>
						)}

						{append && <div className="appended">{append}</div>}
					</div>
				</div>
			</div>
		</section>
	);
};
